 .home-books2{
    background: transparent;
}
.books2{
    background: transparent;
}
.book-list{
    width: 100%;
    height: auto;
    /* background: linear-gradient(to right, #66ff00, #a80077); */
    background-color: whitesmoke;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.list-container{
    width: 80%;
    margin: auto;

    margin-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: flex-start;
    
}
.list{
    border-radius: 15px 15px 0 0;
    background-color: white;
    color: #3C3B3F;
    margin: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }
.l-title{
    border-radius: inherit;
    cursor: pointer;
    display: flex;
    height: 80px;
    justify-content: space-between;
    padding: 10px;
    background: #3C3B3F;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #605C3C, #3C3B3F);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #605C3C, #3C3B3F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: whitesmoke;
    font-size: 10px;
    align-items: top;
    gap: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;


}
.l-title h3{
    text-transform: uppercase;

}
.l-more{
    margin-top: 15px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    height: fit-content;
}
.l-text{
    padding: 0 20px 20px 20px;
    
    
}
.l-meta{
    position: relative;
    padding: 0 0px 20px 20px;
}
.show{
    display: flex;
}
.curve{
    border-radius: 15px;
    background: linear-gradient(to right, #605C3C, #3C3B3F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.header-wrapper{
    display: block;
    width: 100%;
    height: 220px;
    overflow: hidden;
    color: whitesmoke;
    position: relative;
}
.header-container{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: whitesmoke;
    padding-top: 20px;
}

.header-img-container{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    
}
.header-img-container img{
object-fit: fill;
width: 100%;
height: 100%
}
.header-content{
    color: whitesmoke;
    position: relative;
    width: 40% ;
}
.header-content h2{
    margin-bottom: 15px;
}
.header-title{
    font-size: 40px;
    line-height: 0.9;

}
.page-btns{
    width: 200px;
    height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.page-btn{
    width: 60px;
    height: 30px;
    background-color: #605C3C;
    color: whitesmoke;
    border-radius: 6px;
    border: none;
    cursor: pointer;
}
.page-selected{
    background-color: #605c3c99;
}
.inner-img{
    width: 92%;
    display: block;
    margin-inline: auto;
    margin-bottom: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */

}
.inner-img img{
    width: 100%;
}
.download{
    margin-left: 20px;
    margin-bottom: 20px;
    text-decoration: none;
    color: red;
}
.download:hover{
    color: green;
}
@media screen and (max-width: 1050px) {
    
    .header-wrapper{
        height: 150px;
    }
}
@media  screen and (max-width: 600px) {
    .list-container{
        grid-template-columns: 1fr;
    }
    
    .header-wrapper{
        height: 100px;
    }
}