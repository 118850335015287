.home{
    display: block;
    background-color: transparent;

}
.home-container {
    max-height: 90%;
    padding: 10px;
    margin-left: 4vw;
    margin-right: 4vw;
    margin-top: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    /* min-width: 400px; */

}
.banner{
    width: 50vw;

    /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */

}
.left-header{
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 400px;
    text-align: left;
    align-items: flex-start;
    margin-top: -50px;
}
.left-header h2{
    font-size: 60px;
    margin-bottom: 10px;
    line-height: 0.9;
    text-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

    
}
.btn{
    margin-top: 20px;
    padding: 10px 10px 10px 10px;
    width: 200px;
    border: 1px transparent solid; 
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    background: #3C3B3F;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #605C3C, #3C3B3F);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #605C3C, #3C3B3F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    cursor: pointer;
    
}
.btn:hover{
    background: transparent;
    border: 1px rgb(253, 248, 248) solid; 
    transition: var(--transition);
    padding: 20px 10px 20px 10px;

}

/*PRODUCTS SECTION STYLES START HERE*/


.home-products{
    background: #3C3B3F;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #605C3C, #3C3B3F);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #605C3C, #3C3B3F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.products{
    width: 80%;
    height: 90%;
    margin: 5% 5% 5% 5%;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.card{
    width: 300px;
    background-color: whitesmoke;
    border-radius: 15px 15px 0px 0;
    margin-bottom: 20px;
    margin-top: 10px;
    height: 440px;
    padding-top: 0;
    color: #3C3B3F;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    position: relative;
    
}
.teamCard{
    height: 450px;

}
.picture{
    height: 300px;
}

.card img{
    width: 300px;
    height: 50%;
    border-radius: 15px 15px 0px 0;
    margin-top: 0px;
    box-shadow: none;
    transition: var(--transition);
    filter: saturate(1)
}
.teamCard img{
    width: 300px;
    height: 76%;
    border-radius: 15px 15px 0px 0;
    margin-top: 0px;
    box-shadow: none;
    transition: var(--transition);
    filter: saturate(1)
}
.picture img{
    width: 300px;
    height: 100%;
    border-radius: 15px 15px 0px 0;
    margin-top: 0px;
    box-shadow: none;
    transition: var(--transition);
    filter: saturate(1)
}


 .card img:hover
    {
        filter: saturate(0.01)
    }

.title{
    font-size: 30px;
    /* font-family: 'Lilita One', cursive; */
    text-transform: uppercase;
    margin-top: 30px;
    transition: var(--transition);
    cursor: pointer;
    color: #191825 #865DFF #E384FF #FFA3FD ;
    color: -webkit-linear-gradient(to right, #A83279, #D38312);  /* Chrome 10-25, Safari 5.1-6 */

    color: linear-gradient(to right, #A83279, #D38312) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: whitesmoke;


}
.title:hover{
    text-decoration: overline 10px  #605C3C;

}
.card-body{
    height: 50%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 20px;
}
.teamCard .card-body{
    height: 20%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 10px;
}
.card-title{
    font-size: 20px;
    margin-bottom: 3px;

    
}
.teamCard .card-title{
    font-size: 18px;
    /* margin-bottom: 10px; */

    
}
.line{
    position: absolute;
    bottom: 0;
    width: 300px;
    height: 15px;
    background: linear-gradient(to right, #A83279, #D38312); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.card:hover .line
{
width: 30px;
transition: 2s ease-in-out;
border-radius: 0 5px 5px 0;

}

.card:hover{
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  
}
.card a{

    margin-top: 0;
    max-width: 120px;
    padding: 5px 20px;
    margin-bottom: 20px;
    text-decoration: none;
    text-align: center;
    transition: var(--transition);
    transition: 800ms ease-in-out;
    font-size: 12px;

    border: 1px transparent solid; 
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    background: #3C3B3F;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #605C3C, #3C3B3F);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #605C3C, #3C3B3F); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
}
.card:hover a
{
background: whitesmoke;
border: 1px #605C3C solid;
color: #605C3C;
}

.card-text{
    margin-bottom: 10px;
}
.home-books{
    height: auto;
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    background-color: whitesmoke;
}
.books{
    display: flex;
    width: 80%;
    height: 90%;
    margin-top: 6%;
    margin-bottom: 6%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    background: whitesmoke;
}
.book-container{
  
    position: relative;
    display: flex;
    align-items: left;
    justify-content: left;
    gap: 10px;
    border-radius: 25px 25px 0 0;
    margin-top: 30px;
    background: #605C3C;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}

.book-container img{
    width: 40%;
    border-radius: 25px 0 0 0;
    filter: saturate(1);
    transition: 1.2s ease-out;


}
.book-container:hover img{
    filter: saturate(0);
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;


}
.btns{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

}
.prev-btn{
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    border: none;
    background-color: transparent;
    transition: var(--transition);
    border-radius: 10px;


}
.prev-btn:hover{
    border: 3px black solid;
}

.next-btn{
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    border: none;
    background-color: transparent;
    transition: var(--transition);
    border-radius: 10px;

}
.next-btn:hover{
    border: 3px black solid;
}
.decor{
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(to right, #A83279, #D38312); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.book-details{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 25px 20px;
    
}
.book-top{
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
}

.meta{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 3px;

}
.book-bottom button a{
    color: #000;
    background-color: whitesmoke;
    text-decoration: none;
}
/* .h-btn{
    display: block;
}
.h-text{
    display: block;
} */

@media screen and (max-width: 1050px) {
    
.header-wrapper{
    height: 150px;
    width: 50%;
}
.h-btn{
    display: none;
}
.h-text{
    display: none;
}
    .home-container {
        width: 100%;
        height: 60%;
        padding: 2vw;
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: left;
        gap: 0;
        background-color: transparent;



    
    }

    .banner{
        width: 50vw;
    
        /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
    
    }
    .left-header{
        width: 30vw;
        margin-top: 20px;
        justify-content: left;
        align-items: left;
        text-align: left;
    }
    .left-header h2{
        font-size: 40px;
        margin-bottom: 10px;
        line-height: 0.9;
    
        
    }
    .hashtag{
        font-size: 14px;
    }
    .btn{
        margin-top: 15px;
        padding: 8px 10px 8px 10px;
        width: 100px;
      
    }
    .text{
        font-size: 15px;
        max-width: 40vw;
    }
    .btn:hover{
        
        font-size: 15px;
        padding: 8px 10px 8px 10px;
    }    

    .book-container{
        flex-direction: column;
    }
    .book-container img{
        width: 100%;
        border-radius: 25px 25px 0 0;

    }

    
}
    
@media screen and (max-width: 600px) {
    .home-container {
        padding: 2px;
        margin: 3vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    
    }
    .banner{
        margin-top: 30px;
        width: 70vw;
    
        /* box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px; */
    
    }
    .left-header{
        margin-top: 0px;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .left-header h2{
        font-size: 40px;
        margin-bottom: 10px;
        line-height: 0.9;
    
        
    }
    .hashtag{
        font-size: 8px;
    }
    .btn{
        margin-top: 15px;
        padding: 8px 10px 8px 10px;
        width: 100px;
      
    }
    .text{
        font-size: 10px;
        max-width: 50vw;
    }
    .btn:hover{
        
        font-size: 15px;
        padding: 15px 10px 15px 10px;
    }    

    .book-container{
        flex-direction: column;
    }
    .book-container img{
        width: 100%;
        border-radius: 25px 25px 0 0;

    }
    
}
