.footer-container{
    background-color: black;
    width: 100%;
    height: 300px;
    color: whitesmoke;
    font-size: 12px;
    padding-bottom: 70px;

}
.footer-wrapper{
    display: flex;
    width: 80%;
    height: 100%;
    justify-content: space-between;
    align-items: top;
    margin-inline: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 40px;

}
.f-left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.f-left-upper{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.f-top-left{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contact-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.socials{
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 12px;
}
.links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
.links a{
    color: whitesmoke;
    text-decoration: none;
}
.f-right{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
/* @media screen and (max-width: 500px) {
    .f-left{
        max-width: 150px;
    }
    
} */
