.admin-container{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}
.admin-wrapper{
    margin: 20px;
    width: 90%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    height: auto;
}
.panel-banner{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    font-size: 12px;
}
.logout{
    background-color: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 12px;

}
.logout:hover{
    color: rgba(255, 0, 0, 0.543);
    text-decoration: underline;
}
.add-book-form{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: auto;
}
.quill{
    height: 200px;
    margin-bottom: 10px;
}
.add-book-btn{
    margin-top: 60px;
}
.get-out{
    width: 90%;
    height: 100px;
    background-color: whitesmoke;
    color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-inline: auto;
    margin-top: 40px;
    padding: 30px;
}
.admin-form input{
    max-width: none;
}
.dd{
    width: 300px;
    height: 60px;
    font-size: 25px;
    color: black;
    background-color: beige;

}
@media screen and (max-width: 600px) {
    .admin-container{
        width: 94%;
    }
    .admin-wrapper{
        flex-direction: column;
    }
    .add-book-form{
        min-width: 85%;
    }
    
    
}