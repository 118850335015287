.App {
  text-align: center;
}

.App-logo {
  height: 40px;
  pointer-events: none;
}

.main-container{
  margin-top: 80px;
  display: grid;
  margin-bottom: 0;
  align-items: center;
  z-index: 999;
  background: #D38312;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #A83279, #D38312);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #A83279, #D38312); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
