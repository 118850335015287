.nav-container{
    width: 100vw;
    height: 80px;
    background-color: #E8E2E2;
    z-index: 9999;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    text-decoration: none;
    position: fixed;
    top: 0;
    left: 0;
    color: rgb(4, 0, 0);
    padding-left: 8vw;
    padding-right: 8vw;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}
.menu{
    display: flex;
    list-style: none;
    flex-direction: row;
    gap: 25px;
    text-decoration: none;
    margin-right: 25px;
    font-size: 14px;

}

.logo{
    margin-left: 25px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.logo h2{
    font-size: 20px;
    text-transform: uppercase;
}
.menu li a{
    text-decoration: none;
    color: #191825 #865DFF #E384FF #FFA3FD ;


    color: #4E6E81;
    color: #F9DBBB;
    color: #2E3840;

    color: #2E3840;
    font-weight: 600;
    

}
.menu li a:hover {
    color: #865DFF;
}

.active-link{
    color: #865DFF;

    font-weight: bolder;
}
.active-link::after{
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    display: block;
    left: (50% - 5px) ;
    background-color: #E8E2E2;
    transform: rotate(45deg);
    margin-top: 20px;

}
.toggle{
    display: none;
    cursor: pointer;
    color: #865DFF;

}
.toggle:hover{
    font-size: 33px;
}
@media screen and (max-width: 1050px) {
    .nav-container{
   
    padding-left: 7vw;
    padding-right: 7vw;
}



.menu{
    gap: 20px;
    margin-right: 25px;
    /* top: 100%; */
}

.menu li a{
    font-size: 12px;
}
.menu li a:hover {
    color: #865DFF;
}
.active-link{
    color: #865DFF;
    /* font-size: 30px; */
}
.active-link::after{
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    display: block;
    left: (50%) ;
    background-color: #E8E2E2;
    transform: rotate(45deg);
    margin-top: 20px;

}

.logo{
    gap: 5px;
}
.logo img{
    width: 30px;
}
.logo h2{
    font-size: 20px;
}
}

@media screen and (max-width: 940px) {
    .nav-container{
   
    padding-left: 3vw;
    padding-right: 5vw;
}
.toggle{
    display: block;
    font-size: 30px;
    transition: var(--transition);
}


.menu{
    
    position: absolute;
    gap: 0;
    top: 100%;
    right: 0;
    padding-top: 0px;
    /* background-color: #E8E2E2; */
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    display: none;
    position: absolute;
    flex-direction: column;
    margin-right: 25px;
    perspective: 300px;



}

.active-link::after{
   display: none;

}
.show-menu{
  display: block;

}
.menu li{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E8E2E2;
    box-shadow: -15px 15px 17px black (0, 0, 0, 0.4);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    /* this one up is dramatic */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

    opacity: 0;
    transform: rotateX(90deg);
    animation: menuAnimation 1s ease forwards;
    transform-origin: top;
    transition: var(--transition);




}
.menu li:hover{
    box-shadow: rgba(0, 0, 0, 0.26) 0px 22px 30px 4px;
    transition: var(--transition);


}
.menu :nth-child(2){
    animation-delay: 200ms;
}
.menu :nth-child(3){
    animation-delay: 400ms;
}
.menu :nth-child(4){
    animation-delay: 600ms;
}
.menu :nth-child(5){
    animation-delay: 800ms;
}
.menu :nth-child(6){
    animation-delay: 1s;
}
.menu :nth-child(7){
    animation-delay: 1.2s;
}

.menu li a{
    font-size: 12px;
    padding: 15px 30px 15px 30px;



}

.logo{
    margin-left: 5px;
    gap: 5px;
}
.logo img{
    width: 30px;
}
.logo h2{
    font-size: 20px;
}
@keyframes menuAnimation {
    to {
        opacity: 1;
        transform: rotateX(0);
    }
    
}
}