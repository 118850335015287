.gallery-container{
    width: 100%;
    height: 100%;

}
.gallery-wrapper{
    width: 90%;
    margin: 30px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* gap: 30px; */
    flex-wrap: wrap;
    column-gap: 30px;
}