.card-text{
    text-align: left;
}
@media screen and (max-width: 412px) {
    .team-body{
        flex-direction: column;
        /* align-items: center;
        justify-content: center; */
    }
    
}