.about-container{
    width: 100%;
    height: 100%;
    display: block;
    background-color: whitesmoke;
    color: black;
    margin-inline: auto;

}
.about-wrapper{
    width: 80%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-inline: 10%;
    margin-top: 60px;
    margin-bottom: 60px;
    gap: 30px;
}

.about-header{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 30%;
    margin-top: 10px;

}
.strike-through{
    width: 100px;
    height: 10px;
    background: #D38312;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #A83279, #D38312);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #A83279, #D38312); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-radius: 5px;
}
.about-title{
    text-align: left;
    text-transform: uppercase;
    font-size: 58px;
    line-height: 1.1;
    color: linear-gradient(to right, #A83279, #D38312); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */;
}
.small-text{
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 15px;
}
.about-body{
    width: 60%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: right;
}
.team-body{
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
}
.about-body p{
    line-height: 1.5;
}
.services-title{
    font-size: 34px;
}
.segment{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 10px;
    align-items: flex-start;
}
.service-img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: flex;
}
.service-img img{
    width: 100%;
    object-fit: cover;

}
@media screen and (max-width : 1050px) {
 /* .map{

 } */
  
}
@media screen and (max-width : 650px) {
    .about-title h2{
        font-size: 48px;
        line-height: 0.9;
    }
    .about-wrapper{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .segment{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 10px;
        align-items: center;
    }
    
}